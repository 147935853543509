//require('./bootstrap');

jQuery( function () { 
  var swiperCabecera = new Swiper(".swiper-cabecera", {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    effect: "creative",
    creativeEffect: {
      prev: {
        shadow: true,
        translate: ["-20%", 0, -1],
      },
      next: {
        translate: ["100%", 0, 0],
      },
    },
    lazy: {
      loadPrevNext: true,
    },
  });

  /********CONTACTO***********/
  $('#form-contacto').on('submit', function (e) {
    e.preventDefault();
    $('#btn-contact').addClass('disabled').attr('disabled', true).text('Enviando...');
    $.post({
    url: '/send-form',
    data: $('#contact-form').serializeArray()
    })
    .done(function() {
    $('#contact-form').trigger('reset');
    $('#alert-success').css('display', 'block');
    $('#alert-error').css('display', 'none');
    })
    .fail(function() {
    $('#alert-success').css('display', 'none');
    $('#alert-error').css('display', 'block');
    })
    .always(function() {
    $('#btn-contact').removeClass('disabled').removeAttr('disabled').text('Enviar');
    });
  })
})